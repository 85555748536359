import React, { useContext} from "react"
import PropTypes from "prop-types"
import loadable from '@loadable/component'

//import { Link } from 'gatsby';
import { isBrowser } from "../../services/auth"
//import LoadingComponent from '../loading'
import { IdentityContext } from '../identity-context'
import { CenterBtn, PrivateRouterBg, Title } from './style'
const LoadingComponent = loadable(() => import('../loading'))

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    let api = useContext(IdentityContext)
       if (!isBrowser()) {
        return (
            <>
                <LoadingComponent/>
            </>
        )
    }
    if (window.localStorage.getItem('GWoS_u') === null&& api.myUser === null) {
        
        return(<div>
            <PrivateRouterBg>
                <Title>You Are Not Allowed to Access This Page, Please</Title>
                <CenterBtn>
                    <button onClick={api.login}>LOG IN HERE</button>
                </CenterBtn>
                <LoadingComponent />

            </PrivateRouterBg>            
        
        </div>)
    }
  return <Component {...rest} />
}
PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
}

export default PrivateRoute