import React from 'react';
import loadable from '@loadable/component'

const ImpowermentVault = loadable(() => import('../partner-with-us/partner-with-us-vault'))
const Head = loadable(() => import('../Head'))


const ResourcesRestrictedPage = () => {

    return (
        <>
                <Head title="Resources" />                                    
                    <ImpowermentVault />                                
            </>
    );
};

export default ResourcesRestrictedPage;

