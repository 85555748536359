import { Col, Container } from 'react-bootstrap';
import styled from 'styled-components';

export const FlipCard = styled.div`
  background-color: transparent;
  width: 320px;
  height: 320px;
  perspective: 1000px;
  @media (max-width: 600px) {
    width: 100%;
    height: 300px;
  }
`;
export const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  ${FlipCard}:hover & {
    transform: rotateY(180deg);
  }
`;

export const FlipCardFront = styled.div`
  background-color: #d83150;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-text: center;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
`;

export const FlipCardBack = styled.div`
  background-color: #d83150;
  color: white;
  padding: 15px;
  align-text: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
`;

export const NominateButton = styled.div`
  margin: 50px 70px 10px 70px;
  background-color: transparent;
  border: 2px solid white;
  font-size: 14px;
  padding: 4px 8px;
`;

export const ContainerWrapper = styled(Container)`
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;

  max-width: 950px;
  @media (max-width: 600px) {
    padding: 5px;
  }
`;

export const ColumnWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

export const BackText = styled.p`
  font-size: 14px;
  text-align: justify;
`;
export const BackList = styled.ul`
  font-size: 13px;
  text-align: left;
  margin-left: 16px;
  list-style: circle;
`;
export const FrontText = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

export const LinkTag = styled.a`
  text-decoration: none;
  &:hover {
    color: white;
  }
`;

export const Title = styled.h2`
  color: #d83150;
  letter-spacing: 7px;
  text-align: center !important;
  margin-bottom: 0;
  padding: 20px 0;
  font-weight: bold;
  @media (max-width: 600px) {
    letter-spacing: 5px;
  }
`;
