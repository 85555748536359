export const links = [
  {
    path: '/',
    text: 'home',
  },
  {
    path: '/about',
    text: 'about us',
  },
  {
    path: '/app/chapel',
    text: 'GWOS chapel',
  },

  {
    path: '#',
    text: 'Meet Flori',
  },
  {
    path: '#',
    text: 'events',
  },
  // {
  //     path: '/app/resources',
  //     text: 'Resources'
  // },
  // {
  //     path: '/partnerWithUs',
  //     text: 'partner with us'
  // },
  {
    path: '/partnerWithUs',
    text: 'partner with us',
  },
  {
    path: '/prayerRequest',
    text: 'Prayer request',
  },
  {
    path: '#',
    text: 'Projects',
  },
  {
    path: '/praiseReports',
    text: 'Praise reports',
  },
  {
    path: '/blog',
    text: 'blog',
  },
  {
    path: '/shop',
    text: 'shop',
  },
];

//export const links = [
//    {
//        path: '/',
//        text: 'home',
//    },
//    {
//        path: '#',
//        text: 'about',
//    },
//    {
//        path: '#',
//        text: 'events',
//    },
//    {
//        path: '#',
//        text: 'partner',
//    },
//    {
//        path: '/shop',
//        text: 'shop',
//    },
//    {
//        path: '/contact',
//        text: 'contact',
//    },
//    {
//        path: '/blog',
//        text: 'blog',
//    }
//]

export const sublinkMeetFlory = [
  {
    path: '/meetFlori',
    text: 'Flori',
  },
  {
    path: '/contact',
    text: `let's connect`,
  },

  //{
  //    path: '#',
  //    text: `Work with me`,
  //},
  //{
  //    path: '#',
  //    text: `Book Flori`,
  //}
];
export const sublinkEvents = [
  {
    path: '/app/award-night',
    text: 'Award Night 2024',
  },
  {
    path: '/app/award-winners',
    text: 'Award Winners 2023',
  },
  {
    path: '/app/nominations',
    text: 'Nominations',
  },
  {
    path: '/app/book-tickets',
    text: 'Book Tickets',
  },
  {
    path: '/app/gallery',
    text: 'Picture gallery',
  },
  { path: '/app/global-prayer-walk', text: 'Global Prayer Walk' },
];
export const sublinkProjects = [{ path: '/app/empowering-women', text: 'SHE IS EMPOWERED' }];
//export const sublinkPartner = [
//    {
//        path: '/partnerWithUs',
//        text: 'partner with us'
//    },
//    {
//        path: '/donate',
//        text: 'donate'
//    }]

export const footerLinks = [
  {
    path: '/',
    text: 'home',
  },
  {
    path: '/about',
    text: 'about',
  },
  {
    path: '/donate',
    text: 'donate',
  },

  {
    path: '/shop',
    text: 'shop',
  },
  {
    path: '/contact',
    text: 'contact',
  },
  {
    path: '/blog',
    text: 'blog',
  },
];
