import styled from 'styled-components';
import Card from 'react-bootstrap/Card';

export const StyledCard = styled(Card)`
  border: 0.7px solid #ffb900;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin-left: auto;
  margin-right: auto;
  background: #fff !important;
  padding: 10px;
  //font-size: 16.8px;
  padding-bottom: 2rem !important;

  &:hover {
    cursor: pointer;
  }
`;
export const Prayers = styled.div`
  margin-bottom: 6rem;
`;
export const Subtitle = styled.h4`
  color: ${({ theme }) => theme.colors.mediumRedViolet};
  text-align: center;
  margin-bottom: 3rem;
`;
export const TonguesOfFire = styled.span`
  color: ${({ theme }) => theme.colors.mediumRedViolet};
`;
export const Content = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;
export const StyledCardText = styled.div`
  text-align: center;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
`;
//royalBlue jungleGreen
export const CardTitle = styled.h4`
  margin-top: 0.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.mediumRedViolet};
`;
export const Title = styled.h2`
  letter-spacing: 7px;
  text-align: center;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.colors.mediumRedViolet};
  &:before {
    content: '';
    display: block;
    border-top: 1.5px solid ${({ theme }) => theme.colors.mediumRedViolet};
    max-width: 10rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.3rem;
  }
`;
export const ContainerWelcomeImg = styled.div`
  padding: 3rem;
  padding-right: 5rem;
  zindex: 8;
  position: 'relative';
`;

export const InnerBoxWelcomeImg = styled.div`
  background: ${({ theme }) => theme.colors.mediumRedViolet};
  zindex: 2;
  position: 'relative';
`;
