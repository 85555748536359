import React from 'react';
import { Link } from 'gatsby';
import loadable from '@loadable/component'

import { theme } from '../../styles/theme';
import { ThemeProvider } from '../../styles/ThemeProvider';
//import Layout from '../Layout';
import Head from '../Head';
//import Loading from '../loading'
const Layout = loadable(() => import('../Layout'))
const Loading = loadable(() => import('../loading'))

const Default = () => {

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Head title="Default" />
                <Loading />
                <Link to="/">Back to home page</Link>
            </Layout>
        </ThemeProvider>
    );
};

export default Default;
