import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiDonateBlood } from 'react-icons/bi';
import { FaChild, FaHandPaper, FaRegHandshake } from 'react-icons/fa';
import { FiAward } from 'react-icons/fi';
import { GiFamilyHouse, GiLifeBar, GiThroneKing, GiFamilyTree } from 'react-icons/gi';
import { ImWoman } from 'react-icons/im';
import { CgBoy } from 'react-icons/cg';
import { FaOdnoklassniki } from 'react-icons/fa';
import loadable from '@loadable/component';
import { ColumnWrapper, ContainerWrapper, Title } from './style';
import { MdFamilyRestroom } from 'react-icons/md';
import { FaChessKing } from 'react-icons/fa';
import { MdOutlineSocialDistance } from 'react-icons/md';
import { GiCrystalShine } from 'react-icons/gi';
import { IoIosPeople } from 'react-icons/io';
import TitleAppearOnScroll from '../../animations/title-appear-onscroll';
import { SubtitleSection1 } from '../donate/style';

const FlipCards = loadable(() => import('./flip-card'));
export default function AwardCategories() {
  return (
    <div className="py-4">
      <ContainerWrapper>
        <Row>
          <Title>Award Categories</Title>
          <p className="p-4 text-justify">
            Nominations are now open, each person or organisation nominating can enter as many
            people into as many categories they wish. The same person can be nominated for multiple
            categories, but entries must relate to the corresponding category. In order for your
            nominee to have the best chance of being selected as a finalist by our judges, they will
            be looking for you to detail why they are deserving of an award.
          </p>
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'center' }} id="aaaaaaaaaaaaaaaaaaaas">
          {data.map(d => (
            <ColumnWrapper xs={12} md={4}>
              <FlipCards
                icon={d.icon}
                frontText={d.frontText}
                description={d.description}
                question={d.question}
              />
            </ColumnWrapper>
          ))}
        </Row>
        <div style={{ margin: '40px 0px 40px 0px' }}>
          <p>
            Each category is designed to recognize individuals and organizations making meaningful
            impacts across different spheres of influence. When nominating someone, please provide
            as much detail and evidence as possible to help our judges evaluate the nominee's
            achievements and contributions.
          </p>
          <p>
            <b>Nomination Deadline:</b> All nominations must be submitted by 11:59 PM on the 5th of
            October 2024.
          </p>
        </div>
        <h4 style={{ textAlign: 'justify' }}>
          {' '}
          <b>
            Ten compelling reasons to nominate someone in Your community for a recognition award.
          </b>
        </h4>
        <ol type="1" className="px-4">
          {ten_things.map((thing, i) => (
            <li key={i} className="mb-3" style={{ textAlign: 'justify' }}>
              {i + 1}. {thing}
            </li>
          ))}
        </ol>
      </ContainerWrapper>
    </div>
  );
}

const data = [
  {
    icon: <BiDonateBlood style={{ color: 'white', display: 'block', margin: 'auto' }} />,
    frontText: 'Global Impact Award 2023-2024',
    description:
      'Awarded to a woman whose leadership, advocacy, or philanthropy has driven positive change on a global scale.',
    question: [
      'How has she influenced global change?',
      'Describe the international communities she has impacted.',
      'What future global initiatives is she planning?',
    ],
  },
  {
    icon: <FiAward style={{ color: 'white', display: 'block', margin: 'auto' }} />,
    frontText: 'Inspirational Woman of the Year 2023-2024',
    description:
      'Recognizes a woman whose personal or business achievements have inspired others to pursue their goals.',
    question: [
      'What significant challenges has she overcome?',
      'How has her story motivated others in her community or industry?',
      'Provide specific examples of her positive influence.',
    ],
  },
  {
    icon: <ImWoman style={{ color: 'white', display: 'block', margin: 'auto' }} />,
    frontText: 'Business Influencer Woman of the Year 2023-2024',
    description:
      'For a woman whose business has notably impacted the economy, including expansion and job creation in 2023-2024.',
    question: [
      'How has the business expanded in the past year?',
      'Provide examples of the business’s impact on job creation and industry growth.',
    ],
  },
  // {
  //   icon: <CgBoy style={{ color: 'white', display: 'block', margin: 'auto' }} />,
  //   frontText: 'Nominate a young adult',
  //   description:
  //     ' Do you know a young person who is inspiring & impacting others as an author, poet, creative, speaker etc..',
  //   question: '',
  // },
  {
    icon: <FaRegHandshake style={{ color: 'white', display: 'block', margin: 'auto' }} />,
    frontText: 'International Change Agent Award 2023-2024',
    description: 'Honours a woman leading global change and cross-border initiatives.',
    question: [
      'What specific international initiatives has she led?',
      'How has she influenced global development?',
      'Provide measurable outcomes of her initiatives.',
    ],
  },
  // 5 start
  {
    icon: <FaHandPaper style={{ color: 'white', display: 'block', margin: 'auto' }} />,
    frontText: 'GWOS International Charity Cause Award 2023-2024',
    description:
      'Awarded to a GWOS woman who has improved the lives of widows, orphans, or disadvantaged families through charity work.',
    question: [
      'How has her work benefited vulnerable groups?',
      'Provide details on the scale and sustainability of her initiatives.',
    ],
  },

  {
    icon: <GiFamilyTree style={{ color: 'white', display: 'block', margin: 'auto' }} />,
    frontText: 'Woman in Community - Change Agent Award 2023-2024',
    description:
      'Recognizes a woman who has created lasting change in her community through practical efforts like feeding the homeless, volunteering, or advocating for survivors.',
    question: [
      'What specific community initiatives has she led?',
      'Describe the challenges she has addressed and the long-term impact of her work.',
    ],
  },

  {
    icon: <FaChild style={{ color: 'white', display: 'block', margin: 'auto' }} />,
    frontText: 'Making a Difference Service Award 2023-2024',
    description:
      'Honours those who have made significant contributions to their community through service and dedication.',
    question: [
      'How has the nominee demonstrated exceptional service?',
      'Provide examples of the causes supported and their impact.',
    ],
  },

  {
    icon: <FaOdnoklassniki style={{ color: 'white', display: 'block', margin: 'auto' }} />,
    frontText: 'Entrepreneur of the Year 2023-2024',
    description:
      'For a new entrepreneur (male or female) whose business has achieved growth and success in the past year.',
    question: [
      'What industry is the business in, and what challenges were overcome?',
      'Provide details on the business’s growth and impact on the industry or community.',
    ],
  },
  {
    icon: <IoIosPeople style={{ color: 'white', display: 'block', margin: 'auto' }} />,
    frontText: 'Family in Business Award 2023-2024',
    description:
      'Recognizes a woman who runs a family business where multiple members contribute, creating a positive community impact.',
    question: [
      'How do family members collaborate in the business?',
      'What challenges have they faced, and how have they positively impacted the community?',
    ],
  },
  {
    icon: <FaChessKing style={{ color: 'white', display: 'block', margin: 'auto' }} />,
    frontText: 'Kingdom Builder Award 2023-2024',
    description:
      'For a person (male or female) who uses their resources to support and expand God’s Kingdom through ministry or charitable efforts.',
    question: [
      "How has the nominee supported the Kingdom's work with their resources?",
      'Provide examples of the initiatives or ministries they’ve impacted.',
    ],
  },

  {
    icon: <GiCrystalShine style={{ color: 'white', display: 'block', margin: 'auto' }} />,
    frontText: 'Social Impact Award 2023-2024',
    description:
      'Recognizes a woman whose social initiatives have created sustainable, positive change in her community.',
    question: [
      'What social issues is she addressing?',
      'How has her work created measurable change, and what is the long-term impact?',
    ],
  },
];

const ten_things = [
  'Recognize deserving individuals: Nominating someone gives you the opportunity to celebrate the achievements of those who truly deserve recognition.',
  "Spread love and unity: It's an act of love that benefits the entire community, fostering a sense of togetherness.",
  'Express gratitude: Show your appreciation for their selfless contributions and dedication to making a difference.',
  'Acknowledge impact: Let them know that their efforts are noticed and appreciated, inspiring them to continue their positive work.',
  'Promote positive attention: Nominations bring positive attention to your community, highlighting its inspiring individuals.',
  'Raise awareness: Shed light on the needs and challenges of your community, encouraging support and understanding.',
  'Celebrate good works: Create awareness about the impactful projects and initiatives being undertaken in your community.',
  'Inspire others: Set a positive example for other communities, encouraging them to follow suit and recognize their local heroes.',
  'Strengthen community bonds: Unite your community around a common cause, fostering collaboration and support.',
  'Celebrate together: Join as a community to celebrate the nominees and their outstanding contributions.',
];
