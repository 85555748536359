import React from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import ReactAudioPlayer from 'react-audio-player';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Row } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Audio from '../../images/audio.mp3';
const AwardWinners = () => {
  const data = useStaticQuery(graphql`
    query AwardWinnerQuery {
      contentfulAwardWinners {
        id
        title
        images {
          image {
            gatsbyImageData(height: 550, quality: 90, width: 550)
          }
        }
      }
    }
  `);

  return (
    <Container className="flex justify-center">
      <div style={{ display: 'none' }}>
        <ReactAudioPlayer src={Audio} autoPlay controls loop />
      </div>
      <Row>
        <div className="p-4">
          <h3 className="text-center">
            <strong>{data?.contentfulAwardWinners.title}</strong>
          </h3>
        </div>
      </Row>
      <Row className="flex justify-center">
        {data?.contentfulAwardWinners.images[0] && (
          <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Carousel autoPlay infiniteLoop width={500} interval={4000}>
              {data?.contentfulAwardWinners.images.map((e, i) => (
                <div key={i}>
                  {e.image !== undefined ? (
                    <GatsbyImage
                      image={getImage(e.image !== undefined ? e.image.gatsbyImageData : null)}
                      alt={e.subtitle !== null || e.subtitle !== undefined ? `${e.subtitle}` : null}
                    />
                  ) : null}
                </div>
              ))}
            </Carousel>
          </Col>
        )}
      </Row>
    </Container>
  );
};
export default AwardWinners;
