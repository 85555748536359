import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CloseBtnPosition } from './style';

const ModalTempConstruction = ({
  showModalGallery,
  handleCloseModalGallery,
  image,

  subtitle,
  alt,
}) => {
  return (
    <>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        show={showModalGallery}
        onHide={handleCloseModalGallery}
        backdrop="static"
        keyboard={true}>
        {/* <Modal.Header className="border-0">
          <CloseBtnPosition>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleCloseModalGallery}>
              <span aria-hidden="true" className="modalCloseButton">
                &times;
              </span>
            </button>
          </CloseBtnPosition>
        </Modal.Header> */}
        <Modal.Dialog
          style={{
            zIndex: '10',
            height: '100%',
            width: '100%',
            margin: '0px',
            position: 'relative',
            border: '5px',
            borderStyle: 'dotted',
          }}>
          <div style={{ position: 'fixed', top: '2%', left: '70%', zIndex: '10' }}>
            <button
              type="button"
              className="close"
              aria-label="Close"
              style={{ color: 'white' }}
              onClick={handleCloseModalGallery}>
              <span aria-hidden="true" className="modalCloseButton">
                &times;
              </span>
            </button>
          </div>
          {image !== undefined && image !== null ? (
            <GatsbyImage
              backgroundColor="black"
              layout="constrained"
              objectFit="scale-down"
              image={getImage(image !== undefined ? image : null)}
              alt={alt !== null || alt !== undefined ? `${alt}` : null}
            />
          ) : null}
        </Modal.Dialog>
        {/* <Modal.Footer>{subtitle}</Modal.Footer> */}
      </Modal>
    </>
  );
};
export default ModalTempConstruction;
