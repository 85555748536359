import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import loadable from '@loadable/component'

import RichText from '../shared/RichText'
import {        
    TonguesOfFire,    
    Subtitle,
    StyledCard,
    CardTitle,
    StyledCardText,
    Content,
    Prayers
} from './style'
//import TitleAppearOnScroll from '../../animations/title-appear-onscroll'
import { ThemeProvider } from 'styled-components'
import {
    StyledButton,
} from '../Button/template'
const TitleAppearOnScroll = loadable(() => import('../../animations/title-appear-onscroll'))


const ChapelComponent = (data) => {    
  
    return (
        <section>
            <Container fluid className='p-0'>
                <Row>
                    <Col>                        
                        {data?.data?.contentfulAChapelContainer?.extraTitleAndSubtitle.map(e => <Prayers key={e.id}>
                            <TitleAppearOnScroll>
                                <Subtitle>{e?.subtitle ? <RichText raw={e?.subtitle?.raw} /> : null}</Subtitle>
                            </TitleAppearOnScroll>

                            

                            <Content>{e?.content ? <RichText raw={e?.content?.raw} /> : null}</Content>
                            <Content><TonguesOfFire><a href='https://bit.ly/3aJwB8d' target="_blank" rel="noopener noreferrer">Daily Tongues of Fire -</a></TonguesOfFire>
                                12 noon Praying in the spirit for 30 minutes on different topics
                                (Monday - Friday).
                            </Content>
                        </Prayers>)}
                        
                </Col>
                </Row>
            </Container>
            <div className='backgroundLilac'>
            <Container>
                {<Row className='g-0 justify-content-center '>
                    {
                        data?.data?.contentfulAChapelContainer?.cards?.map(e => <Col xs={12} md={6} lg={4}  className='my-5' key={e.id}>
                            <StyledCard style={{ maxWidth: '25rem' }} key={e.id} className='h-100 zoomOnHover border pb-0 mb-0 ml-2 mr-2'>
                                <div onClick={
                                    () => window.open(e.webLink, '_blank', 'noreferrer')
                                }
                                    role="presentation"
                                    className='p-0'
                                >
                                <CardTitle>{e?.title}</CardTitle>
                                {e.image !== undefined ?
                                    <GatsbyImage image={getImage(e.image !== undefined ? e.image.gatsbyImageData : null)} alt={e.title !== null ||
                                        e.alt !== undefined ? `${e.title}` : null} />
                                    : null
                                }
                                    <Card.Body className='h-100 m-0 pb-0 mb-0'>
                                        
                                        <StyledCardText> {e?.cardBody !== null && e.cardBody !== undefined ?
                                            < RichText raw={e?.cardBody?.raw} /> :
                                            null}
                                        </StyledCardText>
                                    </Card.Body>
                                </div>                              
                            </StyledCard>
                        </Col>)
                    }
                </Row>}
                </Container>
            </div>
            <Container>
                <Row className='justify-content-around'>
                    <Col xs={12} sm={8} md={6} lg={4} className='mt-5'>
                        <ThemeProvider theme={{ color: '#f1f1f1', background: ({ theme }) => theme.colors.royalBlue }}>
                            <StyledButton type="button" onClick={
                                () => window.open('https://bit.ly/3aJwB8d', '_blank', 'noreferrer')
                            }>
                                Register
                                            </StyledButton>
                        </ThemeProvider>
                    </Col>
                </Row>
            </Container>
            

        </section>
    )
}
export default ChapelComponent