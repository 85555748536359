import React from 'react';
import loadable from '@loadable/component';
import { ContainerWrapper, RowWrapper, SubTitle, SubTitleText, Title } from './style';

const Tickets = loadable(() => import('./Ticket'));

export default function BookTickets() {
  return (
    <ContainerWrapper>
      <RowWrapper className="">
        <Title>Book Tickets</Title>
        <SubTitle>GWOS Gratitude Dinner & Award Night 2024</SubTitle>
        <SubTitleText>
          Saturday 23rd November, 2024 DoubleTree by Hilton Dartford Bridge, Dartford DA2 6QF
          Time:5.00pm
        </SubTitleText>
      </RowWrapper>
      <Tickets />
    </ContainerWrapper>
  );
}
