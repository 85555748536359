import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { StaticImage } from 'gatsby-plugin-image';
import ModalImageGallery from '../modals/modal-image-gallery';
import { Title, PageTitle, ImageAlign, ImageTitleWrapper } from './style';

const EventsMainComponent = ({ location }) => {
  const data = useStaticQuery(graphql`
    query GallerySingleQuery {
      allContentfulAEventGalleryNew {
        edges {
          node {
            mainContainerTitle
            slug
            mainContainer {
              title
              containerImages {
                gatsbyImageData(width: 600, height: 650, quality: 90)
              }
            }
          }
        }
      }
    }
  `);

  const [showModalGallery, setShowModalGallery] = useState(false);
  const handleCloseModalGallery = () => setShowModalGallery(false);
  const handleShowModalGallery = () => setShowModalGallery(true);
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState(null);
  const [subTitle, setSubtitle] = useState(null);
  const [alt, setAlt] = useState(null);

  return (
    <div className="mb-4">
      <section>
        <Container>
          <ModalImageGallery
            showModalGallery={showModalGallery}
            handleCloseModalGallery={handleCloseModalGallery}
            image={image}
            title={title}
            subtitle={subTitle}
            alt={alt}
          />
          <Row>
            <Col className="m-0 p-0">
              <ImageTitleWrapper>
                <ImageAlign>
                  <StaticImage
                    src="../../images/GWOS-logo-transparent.png"
                    alt="GWOS logo"
                    placeholder="blurred"
                    layout="constrained"
                    width={60}
                    height={60}
                  />
                </ImageAlign>
                <PageTitle>EVENTS AND PROJECTS GALLERY</PageTitle>
              </ImageTitleWrapper>
            </Col>
          </Row>
          {data &&
            data.allContentfulAEventGalleryNew.edges.map((d, i) => {
              return (
                <div key={i}>
                  {location.pathname && location.pathname === '/app/gallery/' + d.node.slug && (
                    <>
                      <Row>
                        <Col className="m-0 p-0">
                          <Title style={{ textTransform: 'uppercase' }}>
                            {d.node.mainContainerTitle}
                          </Title>
                        </Col>
                      </Row>
                      {d.node.mainContainer &&
                        d.node.mainContainer.map((mainCont, i) => (
                          <React.Fragment key={i}>
                            <Row className="my-5">
                              <Col className="my-2" sm={12} md={12}>
                                <p className="text-left bold">{mainCont.title}</p>
                              </Col>
                              {mainCont.containerImages &&
                                mainCont.containerImages.map((img, i) => (
                                  <Col xs={12} sm={6} md={4} lg={3} xl={3} className="my-2" key={i}>
                                    <div
                                      onClick={async () => {
                                        await setImage(img.gatsbyImageData);
                                        await setTitle(mainCont.title);
                                        await setSubtitle(mainCont.title);
                                        await setAlt(mainCont.title);
                                        await handleShowModalGallery();
                                      }}
                                      onKeyDown={handleShowModalGallery}
                                      className="imageGalleryContainer"
                                      role="button"
                                      aria-hidden>
                                      <div className="galleryImage">
                                        <GatsbyImage
                                          style={{ minHeight: '350px' }}
                                          image={getImage(
                                            img !== undefined ? img.gatsbyImageData : null,
                                          )}
                                          alt={
                                            mainCont.title !== null || mainCont.title !== undefined
                                              ? `${mainCont.title}`
                                              : null
                                          }
                                        />
                                      </div>

                                      <div className="imageMagnifyingGlass"></div>
                                    </div>
                                  </Col>
                                ))}
                            </Row>
                          </React.Fragment>
                        ))}
                    </>
                  )}
                </div>
              );
            })}
        </Container>
      </section>
    </div>
  );
};
export default EventsMainComponent;
