import React, { useState, useEffect} from 'react'
import netlifyIdentity from 'netlify-identity-widget'
import { navigate } from 'gatsby'

export const IdentityContext = React.createContext(null)

const IdentityContextProvider = (props) => {

    const [myUser, setMyUser] = useState(null);
    
    const myLogin = () => {

        netlifyIdentity.open();        
       return ()=> window.location.reload()
        
    }

    const myLogout = () => {

        netlifyIdentity.logout()
        netlifyIdentity.close()  
        navigate('/meetFlori', { replace: true })
        return () => window.location.reload()
    }
    
    useEffect(() => {

       // let ignor = false        
            netlifyIdentity.init({
                container: '#netlify-modal',
                APIUrl: process.env.NETLIFY_IDENTITY_API_URL,
                namePlaceholder: 'user name',
                locale: 'en'
            })
        const user = netlifyIdentity.currentUser()

        setMyUser(user)

        return () => {            
          //  ignor = true
         //   netlifyIdentity.close()
           // setMyUser(null)
        }
    },[])      

        netlifyIdentity.on('login', user => {
            netlifyIdentity.refresh()//.then((jwt) => console.log(jwt))//////////////////////////////////////////////////////////////////////////////////
            window.localStorage.setItem('GWoS_u', user)
            netlifyIdentity.close()
            setMyUser(user)
            //  netlifyIdentity.refresh().then((jwt) => console.log('myJWT** '+jwt))
            //console.log('user: ' + JSON.stringify(user))/////////////////////////////////////////////////////////////////////////////////////////////////////////
            //console.log('token: ' + user.user_metadata.full_name)/////////////////////////////////////////////////////////////////////////////////////////////////////////
            return () => navigate('/blog')
        });

        netlifyIdentity.on('logout', () => {
            netlifyIdentity.logout();
            netlifyIdentity.close()
            setMyUser(null)
            window.localStorage.removeItem('GWoS_u')   
            // 
            //window.location.reload()
            return () => window.location.reload()
        });
        
        netlifyIdentity.on('open', () => {           
            netlifyIdentity.open()
            
            return () => netlifyIdentity.close()
        });

        netlifyIdentity.on('close', () => {
    //       
            netlifyIdentity.close()
            
            
            return () => window.location.reload()
        });        
    
    return (

        <IdentityContext.Provider value={{ login: myLogin, logout: myLogout, myUser }}>            
            {props.children}
        </IdentityContext.Provider>)
}
export default IdentityContextProvider;
