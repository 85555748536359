import loadable from '@loadable/component';
import React from 'react';

const GlobalPrayerWalkMain = loadable(() => import('./event-main'));

const GlobalPrayerWalk = () => {
  return (
    <>
      <GlobalPrayerWalkMain />
    </>
  );
};
export default GlobalPrayerWalk;
