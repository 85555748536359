import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import loadable from '@loadable/component';

import RichText from '../shared/RichText';
//import IconCheck from '../icons/checkBdge'
import { Title } from './style';

//import WelcomChapelImg from './welcome-chapel-img'
//import TitleMoveOnLoading from '../../animations/title-move-on-loading'
const IconCheck = loadable(() => import('../icons/checkBdge'));
const WelcomChapelImg = loadable(() => import('./welcome-chapel-img'));
const TitleMoveOnLoading = loadable(() => import('../../animations/title-move-on-loading'));

const WelcomeChapelComponent = data => {
  return (
    <section>
      <Container>
        <Row className="pt-5">
          <Col xs={12}>
            <TitleMoveOnLoading>
              <Title>{data?.data?.contentfulAChapelContainer?.title}</Title>
            </TitleMoveOnLoading>
            <div
              style={{
                textAlign: 'center',
                marginBottom: '6rem',
                background: '#fff2f2',
                padding: '5px 20px',
                borderRadius: '20px',
              }}>
              {data?.data?.contentfulAChapelContainer?.description !== undefined ? (
                <RichText raw={data?.data?.contentfulAChapelContainer?.description.raw} />
              ) : null}
            </div>
          </Col>
          <Col xs={12} md={5}>
            <WelcomChapelImg />
          </Col>
          <Col xs={12} md={7}>
            <div>
              {data?.data?.contentfulAChapelContainer?.welcomeChapelParagraphs?.map((e, i) => (
                <div key={i} style={{ display: 'flex', flexGrow: 1, marginTop: '2rem' }}>
                  <div>
                    <IconCheck key={i} />
                  </div>
                  <div style={{ marginLeft: '.7rem' }}>
                    {e?.text !== undefined ? <RichText raw={e?.text.raw} /> : null}
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default WelcomeChapelComponent;
