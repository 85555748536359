import { createGlobalStyle } from 'styled-components';
import { above, below } from './media';

export const GlobalStyle = createGlobalStyle`
    :root {
      --featureLinearGradient: linear-gradient(to right bottom, rgba(255, 185, 0, 0.85),  rgba(255, 119, 48, 0.85));
    }

    *,
    *::after,
    *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    border: 0 solid;
    overflow-wrap: break-word;

    /* Types */
    --headingFont: 'Lato', sans-serif;
    --bodyFont: 'Lato', sans-serif;
    }


    /**
      width: [1200px - 1800px]
      1rem = 10px, 10/16 = 62.5%
    */

    html {
    /* background: yellow; */
      font-size: 62.5%; /* 1rem = 10px, 10/16 = 62.5% */

      /* When using max-width sizes, cascade from larger sizes first */

      /* width < 1800, 1rem = 12px, 12/16 = 75% */
      /* 112.5em and higher*/
      /* Jonas using 75% */
      ${above.bigDesktop`
        /* background: blue; */
        font-size: 80%;
      `}

      /* 112.5em and smaller */
      /* Jonas using 75% */
      ${below.bigDesktop`
        /* background: blue; */
        font-size: 80%;
      `}

      /* width < 1200 */
      /* Jonas using 56.25% */
      ${below.tabLand`
      /* background: red; */
      font-size: 70%; /* 1rem = 11.2px, 11.2/16 = 70% */
      `}

      /* width < 900 */
      /* Jonas using 50% */
      ${below.tabPort`
      /* background: green; */
      font-size: 60%; /* 1rem = 9.6px, 9.6/16 = 60% */
      `}
    }

    body {
      scroll-behavior: smooth;
      text-rendering: optimizeSpeed;
      font-family: var(--bodyFont)!important;
      font-weight: 400;
      font-size: 1.6rem!important;
      line-height: 1.5; /* Jonas uses 1.7 */
      color: 	hsl(0, 0%, 47%); /* #777 */
      box-sizing: border-box;
      padding: 3rem;

        ${below.tabPort` /* *width < 900 */
            /* padding: 0; */
        `}
    }

    /* Make images easier to work with */
    img {
      max-width: 100%;
    }

    p {
font-size: 17.92px;
margin-bottom: 1rem;}

    h1, h2, h3, h4, h5 {
      margin: 3rem 0 1.38rem;
      font-family: var(--headingFont);
      font-weight: 400;
      line-height: 1.3;
    }

    h1 {
      margin-top: 0;
      font-size: 3.052rem;
    }

    h2 {font-size: 2.441rem;}

    h3 {font-size: 1.953rem;}

    h4 {font-size: 1.563rem;}

    h5 {font-size: 1.25rem;}

    small, .text_small {font-size: 0.8rem;}

    /* Inherit fonts for inputs and buttons */
    button,
    input,
    textarea,
    select {
      color: inherit;
      font: inherit
    }

    /* Pixelate effect for the images (product images) */
    .gatsby-image-wrapper img[src*=base64\\,] {
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
    }


`;
