
import netlifyIdentity from 'netlify-identity-widget'
//const isBrowser = typeof window !== `undefined`
export const isBrowser = () => typeof window !== "undefined"

export const isLoggedIn = () => {
    if (!isBrowser()) return false
    const user = netlifyIdentity.currentUser();
    console.log('from auth isLogin ***'+user)
    return user
}
