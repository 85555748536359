import React from 'react';
import loadable from '@loadable/component';

//import ImageGalleryComponent from './image-gallery'
//import BreadcrumbComponent from '../shared/breadcrumb'
const ImageGalleryComponent = loadable(() => import('./image-gallery'));

const GalleryPage = () => {
  return (
    <>
      <ImageGalleryComponent />
    </>
  );
};
export default GalleryPage;
