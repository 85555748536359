import styled from 'styled-components';

export const Title = styled.h4`
  //letter-spacing: 7px;
  text-align: center;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.colors.mediumRedViolet};
`;

export const PageTitle = styled.h2`
  letter-spacing: 7px;
  text-align: center;
  margin-top: 3rem;
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.mediumRedViolet};
  @media only screen and (max-width: 600px) {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;
export const ImageAlign = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 600px) {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
  }
`;

export const ImageTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

export const CompactAlbumMain = styled.div`
  float: left;
  padding: 0 12px 4px 0 !important;
  margin: 4px 15px !important;
  text-align: left;
  cursor: pointer;
`;

export const CompactAlbumBox = styled.div`
  margin: 0 !important;
  padding: 8px !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
  border: 1px solid gray;
  border-radius: 6px;
  z-index: 1;
  ::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -7px;
    border-bottom: 1px solid gray;
    border-radius: 6px;
    z-index: -1;
  }
  ::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -4px;
    border-bottom: 1px solid gray;
    border-radius: 6px;
    z-index: -1;
  }
`;
export const CompactAlbumImage = styled.img`
  margin: 0px !important;
  padding: 0px 0 0 0 !important;
  border: 1px solid black !important;
  max-width: 100%;
  height: auto;
`;
export const CompactAlbumTitle = styled.h4`
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.mediumRedViolet};
`;

export const CompactAlbumTitleLink = styled.a`
  overflow: hidden;
  display: block;
  height: 54px;
`;
