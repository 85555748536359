import styled from 'styled-components'

export const Title = styled.h2`
text-align: center;
padding-top: 3rem;
margin-top:0;
`
export const CenterBtn = styled.div`
//margin-top: 3rem;
display:flex;
justify-content:center;
`;

export const PrivateRouterBg = styled.div`
  background: linear-gradient(to right bottom, rgba(255, 185, 0, 0.85),rgba(255, 119, 48, 1))!important;
    min-Height: 80vh;
    height: 100%;
    padding-bottom: 3rem;`;