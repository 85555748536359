import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
//import { Link } from 'gatsby';
//import links from '../constants/links';
import { below } from '../styles/media';
import { IdentityContext } from './identity-context';

import { isBrowser } from '../services/auth';
import { sublinkMeetFlory, links, sublinkEvents, sublinkProjects } from '../constants/nav-links';
const Nav = styled.div`

}
`;

const CheckBox = styled.input.attrs(props => ({
  type: 'checkbox',
  id: 'bg',
}))`
  display: none;
  &:checked ~ #navBackground {
    // transform: scale(80);

    height: 80%;
    width: 100%;
    border-radius: 0;
    position: fixed;
    top: 0;
    right: 0;
  }

  &:checked ~ #navListContainer {
    opacity: 1;
    width: 100%;
    // top:5rem;
    top: 0;
  }

  &:checked + #navigationLabel #navigationIcon {
    background-color: transparent;
  }

  &:checked + #navigationLabel #navigationIcon:before {
    top: 0;
    transform: rotate(135deg);
  }

  &:checked + #navigationLabel #navigationIcon:after {
    top: 0;
    transform: rotate(-135deg);
  }
`;

const Label = styled.label.attrs(props => ({
  htmlFor: 'bg',
  id: 'navigationLabel',
}))`
  ${tw`bg-white shadow-2xl text-center`}
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: fixed;
  top: 6rem;
  right: 6rem;
  z-index: 2000;
  cursor: pointer;

  &:hover #navigationIcon::before {
    top: -1.2rem;
  }

  &:hover #navigationIcon::after {
    top: 1.2rem;
  }

  ${below.phone`
    top: 3rem;
    right: 3rem;

  `}
`;

const NavBackground = styled.div.attrs(props => ({
  id: 'navBackground',
}))`
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: fixed;
  top: 6rem;
  right: 6rem;
  background-image: radial-gradient(
    ${({ theme }) => theme.colors.carissma},
    ${({ theme }) => theme.colors.carissma2}
  );

  //background-image: linear-gradient(to left,
  //   ${({ theme }) => theme.colors.carissma},
  //   ${({ theme }) => theme.colors.carissma2}
  // );

  z-index: 1000;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  ${below.phone`
    top: 3rem;
    right: 3rem;
  `}
`;

const NavListContainer = styled.div.attrs(props => ({
  id: 'navListContainer',
}))`
  height: 80% !important;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1500;

  opacity: 0;
  width: 0;
  transition: all 0.8s;
  overflow: auto;
  bottom: 1rem !important;
`;

const NavList = styled.ul`
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 10rem;
  ${below.tabPort`
    margin-top:9.5rem;

  `};
  ${below.phone`
    margin-top:7.5rem;

  `};
`;

const List = styled.li`
  ${tw`block py-2 text-2xl px-8 font-light uppercase`}
  text-decoration: none;
  color: white;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50%);
  background-size: 350%;
  transition: all 0.4s;

  &:hover,
  &:active {
    ${tw`text-pink-800`}
    background-position: 100%;
    // transform: translateX(1.2rem);
  }
`;
const ListSublink = styled(List)`
  ${tw`mx-6`}
`;
//const ListNumber = styled.span``;

//const ListItem = styled(Link)`
//  margin: .9rem;
//`;
const ListItem2 = styled.a`
  margin: .6rem!;
`;

//const ListItem = styled.div`
//  margin: .7rem;
//`;
const NavigationIcon = styled.span.attrs(props => ({
  id: 'navigationIcon',
}))`
  ${tw`relative`}
  margin-top: 2.8rem;
  &,
  &::before,
  &::after {
    ${tw`w-12 h-1 bg-gray-600 inline-block`}
  }

  &::before,
  &::after {
    ${tw`absolute left-0`}
    content: '';
    transition: all 0.1s;
  }

  &::before {
    top: -1rem;
  }

  &::after {
    top: 1rem;
  }
`;

const StyledLoginBtn = styled.div`
  background-color: ${({ theme }) => theme.colors.darkViolet};
  font-size: 1.3rem !important;
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  padding-left: 3rem;
`;

const Navbar = () => {
  const api = useContext(IdentityContext);
  const loginOrLogout = () => {
    if (isBrowser()) {
      if (api.myUser === null) {
        return <button onClick={api.login}>Member Sign In</button>;
      } else {
        return <button onClick={api.logout}>Log out</button>;
      }
    }
  };
  const [about, setAbout] = useState(false);
  const handleAbout = () => {
    setEvents(false);
    // setPartners(false)
    setAbout(!about);
    return () => setAbout(!about);
  };

  const [events, setEvents] = useState(false);
  const [projects, setProjects] = useState(false);
  const handleprojects = () => {
    setAbout(false);
    setEvents(false);
    //setPartners(false)
    setProjects(!projects);
    return () => setProjects(!projects);
  };
  const handleEvents = () => {
    setAbout(false);
    //setPartners(false)
    setEvents(!events);
    return () => setEvents(!events);
  };
  //const [partner, setPartners] = useState(false)
  //const handlePartner = () => {
  //    setAbout(false)
  //    setEvents(false)
  //    setPartners(!partner)
  //    return () => setPartners(!partner)
  //}

  return (
    <>
      <StyledLoginBtn>{loginOrLogout()}</StyledLoginBtn>
      <Nav>
        <CheckBox />
        <Label>
          <NavigationIcon>&nbsp;</NavigationIcon>
        </Label>
        <NavBackground>&nbsp;</NavBackground>
        <NavListContainer>
          <NavList>
            {links.map((item, index) => {
              if (item.text === 'Meet Flori') {
                return (
                  <div key={index}>
                    <List onClick={handleAbout}>
                      <ListItem2 href={item.path}>{item.text}</ListItem2>
                    </List>
                    <div className={about === true ? 'visible' : 'hidden'}>
                      {sublinkMeetFlory.map((e, i) => (
                        <ListSublink key={i}>
                          <ListItem2 href={e.path}>{e.text}</ListItem2>
                        </ListSublink>
                      ))}
                    </div>
                  </div>
                );
              }
              if (item.text === 'events') {
                return (
                  <div key={index}>
                    <List onClick={handleEvents}>
                      <ListItem2 href={item.path}>{item.text}</ListItem2>
                    </List>
                    <div className={events === true ? 'visible' : 'hidden'}>
                      {sublinkEvents.map((e, i) => (
                        <ListSublink key={i}>
                          <ListItem2 href={e.path}>{e.text}</ListItem2>
                        </ListSublink>
                      ))}
                    </div>
                  </div>
                );
              }
              if (item.text === 'Projects') {
                return (
                  <div key={index}>
                    <List onClick={handleprojects}>
                      <ListItem2 href={item.path}>{item.text}</ListItem2>
                    </List>
                    <div className={projects === true ? 'visible' : 'hidden'}>
                      {sublinkProjects.map((e, i) => (
                        <ListSublink key={i}>
                          <ListItem2 href={e.path}>{e.text}</ListItem2>
                        </ListSublink>
                      ))}
                    </div>
                  </div>
                );
              }
              {
                /*if (item.text === 'partner') {
                                return <div key={index}>
                                    <List onClick={handlePartner}>
                                        <ListItem2 href={item.path}>
                                            {item.text}
                                        </ListItem2>
                                    </List>
                                    <div className={partner === true ? 'visible' : 'hidden'}>
                                        {sublinkPartner.map((e, i) => <List key={i}><ListItem2 href={e.path}>{e.text}</ListItem2></List>
                                        )}</div>
                                </div>
                            } */
              }

              return (
                <List key={index}>
                  <ListItem2 href={item.path}>{item.text}</ListItem2>
                </List>
              );
            })}
          </NavList>
        </NavListContainer>
      </Nav>
      <div id="netlify-modal"></div>
    </>
  );
};

export default Navbar;
