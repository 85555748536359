import React from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';

import { GlobalStyle } from '../styles/GlobalStyles';

import IdentityContextProvider from './identity-context';
//import Navbar from './Navbar';
//import Footer from './Footer';

import Navbar from './Navbar';
const Footer = loadable(() => import('./Footer'));

const PageContainer = styled.div`
  min-height: 80vh;
  heigh: 80% !important;
`;

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <IdentityContextProvider>
        <Navbar />
        <PageContainer className="page-container">
          <main>{children}</main>
        </PageContainer>
      </IdentityContextProvider>
      <Footer />
    </>
  );
};

export default Layout;
