import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { theme } from '../../styles/theme';
import { ThemeProvider } from '../../styles/ThemeProvider';
//import BreadcrumbComponent from '../shared/breadcrumb'
import ChapelSection from './Chapel';
import Head from '../Head';
//import { IdentityContext } from '../identity-context'
import WelcomeChapel from './welcome-chapel';

const Chapel = () => {
  // const data = useStaticQuery(graphql`
  //   query WelcomeChapelQuery {
  //     contentfulAChapelContainer {
  //       title
  //       description {
  //         raw
  //       }
  //       welcomeChapelParagraphs {
  //         text {
  //           raw
  //         }
  //       }
  //       extraTitleAndSubtitle {
  //         id
  //         subtitle {
  //           raw
  //         }
  //         content {
  //           raw
  //         }
  //       }
  //       cards {
  //         title
  //         webLink
  //         id
  //         image {
  //           gatsbyImageData(layout: FULL_WIDTH, quality: 80)
  //           fluid {
  //             tracedSVG
  //           }
  //         }
  //         cardBody {
  //           raw
  //         }
  //       }
  //     }
  //   }
  // `);

  // let api = useContext(IdentityContext);
  // if (window.localStorage.getItem('GWoS_u') === null && api.myUser === null) { }
  return (
    <ThemeProvider theme={theme}>
      <Head title="GWoS Chapel" />
      {/*window.localStorage.getItem('GWoS_u') === null || api.myUser === null ? null :
                  <BreadcrumbComponent />*/}
      {/* <WelcomeChapel data={data} />
      <ChapelSection data={data} /> */}
    </ThemeProvider>
  );
};

export default Chapel;
