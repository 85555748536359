import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const RowWrapper = styled(Row)`
background-color: ${({ theme }) => theme.colors.mediumRedViolet};
width:100%;
padding:0;
margin:0;
`;

export const DonateButton = styled.div` 
margin:50px 70px 10px 70px;   
background-color: transparent;
border: 2px solid white;
font-size: 14px;

`;

export const ContainerWrapper = styled(Container)` 
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding:0px;
margin: 40px auto;
`;

export const ColumnWrapper = styled(Col)` 
display:flex;
justify-content:center;
align-items: center;
margin-bottom: 15px;

`;

export const TextWrapper = styled.p`
    font-size: 14px;
`
export const Title = styled.h2`
    color: white;
    letter-spacing: 7px;
    text-align: center!important;        
    margin-bottom: 0;
    padding: 5px 0;
    font-weight: bold;
`;
export const SubTitle = styled.h5`
    color: white;
    letter-spacing: 5px;
    text-align: center!important;        
    margin-bottom: 5px;
`;

export const SubTitleText = styled.p`
    color: white;
    letter-spacing: 5px;
    text-align: center!important;        
    margin-bottom: 10px;
    font-size: 12px;
`;

export const TicketHeading = styled.h6`
    color: rgb(71 85 105);
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 3px;       
    margin-bottom: 10;
`;

export const TicketSubHeading = styled.p`
    letter-spacing: 2px;
    font-weight: 600;
    font-size:14px;       
    margin-bottom: 10;
    color:${({ theme }) => theme.colors.mediumRedViolet};
`;

export const TicketText = styled.p`
    letter-spacing: 2px;
    font-weight: normal;
    font-size:13px;       
    margin-bottom: 3;
    color:#334155;
`;

export const Asterisk = styled.p`
    color: ${({ theme }) => theme.colors.mediumRedViolet};
    font-size: 1rem;
    text-align:center;
    @media(max-width: 600px) {
        align-self: start;
        font-size: 15px;
         }
`;

export const CenterItems = styled.div`
    display:flex;
    align-items: center;
    padding-left:10px;
    margin-bottom:5px;
    @media(max-width: 600px) {
    padding-left: 0px;
     }
`;

export const SelectionField = styled.select`
    float:right;
    border:2px solid purple;
    border-radius: 8px;
    font-size: 15px;
    padding:5px;
    @media(max-width: 600px) {
        align-item
        margin: 20px 0px;
        width: 100%
     }
`;

export const Divider = styled.hr`
    border-top: 3px solid #bbb;
    margin-bottom: 30px;
`